module.exports = new Promise(resolve => {
    const remoteUrl = window.location.origin.includes('localhost') ? 'https://cp.cloudmaster.ru/remotejs/packages/remoteEntry.js' : window.location.origin + '/remotejs/packages/remoteEntry.js';
    const script = document.createElement('script')
    script.src = remoteUrl
    script.onload = () => {
      const proxy = {
        get: (request) => window.cm_packages.get(request),
        init: (arg) => {
          try {
            return window.cm_packages.init(arg)
          } catch(e) {
            console.log(e)
            console.log('remote cm_packages already initialized')
          }
        }
      }
      resolve(proxy)
    }

    document.head.appendChild(script);
  });